import { Physics, Debug } from '@react-three/rapier'
import Lights from './Lights.js'
import {Level,} from './Level.js'
import player from './Player.js'
import Player from './Player.js'

export default function Experience()
{
    return <>

        <color args={['#bdedfc']} attach="background" />

        <Physics>
            {/* <Debug /> */}
            <Lights />
            <Level />
            <Player />
        </Physics>
        

    </>
}